import React, {useEffect, useState} from 'react';
import { Header } from '../components/Header_Black';
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';
import { navigate } from "gatsby";

// markup
const CulturePage = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left,
      top: rect.top,
      bottom: rect.bottom
    };
  }

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      const d = document.getElementById("offsetComponent");
      const e = document.getElementById("value-sec");
      const f = document.getElementById("culture-group-pic");
      const g = document.getElementById("quote-sec");
      const h = document.getElementById("list-cultures");
      const overlayClip = document.getElementById("clip-background-overlay");

      const yOfElement = getOffset(d).top;
      const heighOfElement = h?.clientHeight || 1024;

      if (currentPosition >= getOffset(g).bottom && currentPosition < (yOfElement + heighOfElement - 300)) {
        const diff = yOfElement - scrollTop;
        f.style.position = 'fixed';
        f.style.zIndex = 99;
        f.style.width = '100%';
        e.style.transform = `matrix(1, 0, 0, 1, 0, ${diff})`;
        if(currentPosition >= getOffset(g).bottom) {
          overlayClip.style.opacity = 1;
        }
      } else {
        overlayClip.style.opacity = 0;
        f.style.position = 'sticky';
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  // animation for text
  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      const listItems = document.querySelectorAll(".list-culture-item");

      listItems.forEach((item, index) => {
        item.style.opacity = 0;
        if(currentPosition > getOffset(item).top) {
          const modPosition = (currentPosition - getOffset(item).top);
          const abc = (modPosition - item.clientHeight) / item.clientHeight;
          item.style.opacity = abc >= 1 ? 1 : abc;
        }
      });
      
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <main className="font-body text-black mx-auto overflow-hidden">
      <Meta title="Culture Page" bodyClasses="" />
      <Header />
      <section className="culture-section bg-clip-text pb-24 sm:pb-12 bg-no-repeat" id="culturesec">
        <div className="pt-32 sm:pt-20 items-center container mx-auto">
          <div className="lg:ml-4 w-1/2 sm:w-full pr-3">
            <h1 className="px-0 sm:px-4">
              <div className="text-beeg lg:text-[60px] font-extrabold text-white font-Bold text-outline-red tracking-tight">Culture</div>
            </h1>
            <div className="text-[20px] lg:text-sm px-0 sm:px-4 mt-4 ">
              <div className="mb-1.5 leading-normal">If you visit us, you might see MMJ just as the same as other workplaces. We are just a few people in a simple office. Coding and testing are our daily activities.<br/><br/>Though, when you interact with us for a bit more time, you might notice there’s something different. It is about the openness in discussion, the agreement among teams, the comfort in the atmosphere, and trusted relationships. That’s all about our culture that has been built and prioritized from the very first days.</div>
            </div>
            <div className="sm:mx-4">
              <button id="btn_launch-app" onClick={()=>{navigate("/joinOurTeam")}} className="hover:bg-indigo-800 text-white text-sm font-Medium font-medium mt-8 flex items-center bg-tealish sm+:px-6 py-3 rounded-lg sm:w-full ">
                <div className="mx-auto">Join Us</div>
              </button> 
            </div>
          </div>
        </div>
        <img className="w-1/2 sm:min-w-full max-w-[704px] sm:pt-8 sm+:absolute sm+:right-0 sm+:top-32" src="../../banner.png" /> 
      </section>
      <section className="quote-section bg-redGradient" id="quote-sec">
        <div className="text-center text-white pt-24 sm:pt-12 pb-24 sm:pb-12">
          <div className="text-[36px] sm:text-lg font-medium font-Medium leading-normal">
            <div className="pb-2"> We intentionally build a <span className="italic">good company*</span> for good people.</div>
            <div className="pb-4">We want our people to be happy and live purposefully each day.</div>
          </div>
          <div className="italic text-[20px] sm:text-sm">* Being consistently good and for long term.</div>
        </div>
      </section>
      <section className="culture-group-pic" id="culture-group-pic">
        <div className="w-full relative" id="offsetComponent">
          <div className="clip-background">
            <div id="clip-background-overlay"></div>
            <video className='h-full w-full object-cover' autoPlay={true} muted={true}>
              <source src="../../mmjTeam.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="value-section relative pt-24 pb-20" id="value-sec">
            <div className="container mx-auto max-h-screen">
              <div className="mx-auto pt-[100vh] w-full md:w-8/12 px-4 md:px-0" id="list-cultures">
                <div className="text-white text-left pb-12 font-Bold text-[36px] md:text-[60px] text-shadow leading-none">We live with<br/> our values</div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../caring.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[36px] text-white leading-normal text-shadow">Caring</div>
                    <div className="text-[20px] text-white text-shadow leading-tight">Work for others’ happiness.<br/> We prefer to be the givers.</div>
                  </div>
                </div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../ic-impact-oriented.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[40px] text-white leading-normal text-shadow">Impact Oriented</div>
                    <div className="text-[20px] text-white text-shadow">Spend time only on high impact work.<br/>Work effectively and smart.</div>
                  </div>
                </div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../ic-take-initiative.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[40px] text-white leading-normal text-shadow">Take Initiative</div>
                    <div className="text-[20px] text-white text-shadow">The only way that we can be successful is solving problems.<br/>Tackle problems if you see them.</div>
                  </div>
                </div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../ic-be-positive.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[40px] text-white leading-normal text-shadow">Be Positive</div>
                    <div className="text-[20px] text-white text-shadow">We tend to be positive in bad situations and believe that we can overcome difficulties.</div>
                  </div>
                </div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../ic-lets-grow-together.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[40px] text-white leading-normal text-shadow">Let’s Grow Together</div>
                    <div className="text-[20px] text-white text-shadow">Oh, this is a lot. Learn and help others grow as growing alone is hard.</div>
                  </div>
                </div>
                <div className="list-culture-item flex flex-row items-start pb-12">
                  <div className="mr-12">
                    <img className="object-contain w-16 h-16" src="../../ic-disagree-commit.png" />
                  </div>
                  <div className=" w-4/5">
                    <div className="font-Bold pb-3 text-[40px] text-white leading-normal text-shadow">Disagree & Commit</div>
                    <div className="text-[20px] text-white text-shadow">Let’s put all the opinions on the table and just do it.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-people-section pt-20 pb-20 sm:mx-4" id="ourpeoplesec">
        <div className="text-gradient-red text-center pb-16 font-Bold text-[55px]">Our people</div>
        <div className="flex flex-wrap max-w-4xl mx-auto justify-between flex-row">
          <div className="flex flex-col w-9/20 sm:w-full">
            <div className="">
              <div className="rounded-xl shadow-2xl">
                <img className="object contain w-full" src="../../img_2.png"/>
                <div>
                  <img className="object-contain absolute pt-7 pl-3" src="../../invalid-name.png"/>
                  <div className="w-full px-10 pb-6 mx-auto pt-10">Học tập là điểm mạnh rất tốt ở công ty mình. Đặc biệt với các bạn kỹ thuật. Các bạn cảm nhận được văn hóa ở đây và cảm nhận được công ty rất muốn áp dụng những công nghệ mới. Việc này giúp các bạn gắn bó với công ty hơn.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-7 border-l-transparent border-r-transparent border-t-white border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full items-center">
                <img className="ml-4 max-w-[64px]" src="../../avatar.png"/>
                <div className="ml-4 my-auto"><span className="font-Bold">Minh Luan</span> - Project Manager</div>
              </div>
            </div>
            <div className=" pt-8">
              <div className="rounded-xl bg-deGrey">
                <div>
                  <div className="absolute pt-3 pl-3 text-[#c3c3c3] text-2xl font-Bold">“</div>
                  <div className="w-full px-6 pb-6 pt-4">Mọi người ở công ty rất tích cực, khi có 1 ý tưởng hay vấn đề xảy ra, mọi người đều xử lý một cách rất tích cực và nhẹ nhàng để vấn đề được giải quyết và mọi người đều cảm thấy thoải mái.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-10 border-l-transparent border-r-transparent border-t-deGrey border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full">
                <div className=" my-auto pl-6"><span className="font-Bold">Nguyen Thai</span> - Customer Support</div>
              </div>
            </div>
            <div className=" pt-8">
              <div className="rounded-xl bg-deGrey">
                <div>
                  <div className="absolute pt-3 pl-3 text-[#c3c3c3] text-2xl font-Bold">“</div>
                  <div className="w-full px-6 pb-6 pt-4">Em thích nhất là cơ hội học hỏi. Sếp luôn tạo điều kiện cho em thử nghiệm và được làm những việc mới, để em học hỏi. Nếu không biết thì sẽ tìm hiểu và hỏi, không bị cảm giác ép buộc, và rất thoải mái trong công việc.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-10 border-l-transparent border-r-transparent border-t-deGrey border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full">
                <div className=" my-auto pl-6 "><span className="font-Bold">Phuong Thao</span> - Accountant</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-9/20 sm:w-full ">
            <div className="sm:pt-8">
              <div className="rounded-xl bg-deGrey">
                <div>
                  <div className="absolute pt-3 pl-3 text-[#c3c3c3] text-2xl font-Bold">“</div>
                  <div className="w-full px-6 pb-6 pt-4">Khi mình gặp vấn đề, cả công ty sẽ tập trung giải quyết vấn đề, môi trường làm việc thoải mái, ít căng thẳng. Sếp sẵn sàng giúp đỡ tận tình nhân viên khi cần, mọi người đều được tin tưởng.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-10 border-l-transparent border-r-transparent border-t-deGrey border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full">
                <div className=" my-auto pl-6"><span className="font-Bold">Anh Duy</span> - Software Engineer</div>
              </div>
            </div>
            <div className="pt-8">
              <div className="rounded-xl bg-deGrey">
                <div>
                  <div className="absolute pt-3 pl-3 text-[#c3c3c3] text-2xl font-Bold">“</div>
                  <div className="w-full px-6 pb-6 pt-4">We have workplace culture with a “family feel”. Each day, when I wake up, I really want to go to the office to meet our people. I like the way we work: be serious, committed, and focus on high impact things.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-10 border-l-transparent border-r-transparent border-t-deGrey border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full">
                <div className="my-auto pl-6"><span className="font-Bold">Minh Khue</span> - Senior Software Engineer</div>
              </div>
            </div>
            <div className="pt-8">
              <div className="rounded-xl shadow-2xl">
                <img className="object contain w-full" src="../../img_3.png"/>
                <div>
                  <img className="object-contain absolute pt-7 pl-3" src="../../invalid-name.png"/>
                  <div className="w-full px-10 pb-6 mx-auto pt-10">Nếu em làm được một việc gì đấy, em sẽ luôn được ghi nhận. Công ty quan tâm đến nhân viên, đời sống và tinh thần của mỗi người. Môi trường làm việc thoải mái.</div>
                </div>
              </div>
              <div className="w-0 h-0 translate-x-7 border-l-transparent border-r-transparent border-t-white border-x-16 border-t-16"></div>
              <div className="flex flex-row pt-2 w-full items-center">               
                <img className="ml-4 max-w-[64px]" src="../../avatar_2.png"/>               
                <div className="ml-4 my-auto"><span className="font-Bold">Mai Huong</span> - Product Team Member</div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <Footer />
    </main>
  )
}
export default CulturePage